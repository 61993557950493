export default {
  "app_label": "estimation",
  "model_name": "pmsfsortcode",
  "pagination": true,
  "columns": [
    {
      "name": "pk",
      "label": "ID",
      "help_text": null,
      "hidden": true,
      "identifier": true,
      "data_type": "int",
      "search": false,
      "sort": false
    },
    {
      "name": "subheading",
      "label": "Subheading",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "usage",
      "label": "Usage",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": null,
      "child_path": "label",
      "search": true,
      "sort": true
    },
    {
      "name": "sort_code",
      "label": "Sort code",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "int",
      "search": true,
      "sort": true
    },
    {
      "name": "description",
      "label": "Description",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "str",
      "ellipsis": true,
      "search": true,
      "sort": true
    },
    {
      "name": "modified",
      "label": "Modified",
      "help_text": null,
      "hidden": false,
      "identifier": false,
      "data_type": "datetime",
      "search": false,
      "sort": true
    }
  ],
  "filter_by": [
    {
      "label": "Usage",
      "name": "usage",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:lineusage:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    },
    {
      "label": "Subheading",
      "name": "subheading",
      "type": "api_reference",
      "api": {
        "url_name": "api:admin:estimation:subheading:autocomplete",
        "key": "pk",
        "display": "label",
        "pagination": true
      }
    }
  ],
  "export_import": null
}