import React from 'react'
import { Row, Col, Descriptions } from 'antd'

const symbols = {
  "A": "Area",
  "B": "Bracing",
  "D": "Height",
  "F": "Factor",
  "H": "Height",
  "I": "Interior",
  "L": "Length",
  "N": "Number",
  "O": "Openings",
  "P": "Pitch",
  "W": "Width",
  "DM": "Number Of Dims",
  "E1": "Extra 1",
  "E2": "Extra 2",
  "FE": "For each",
  "PF": "Parent Fixings",
  "PT": "Parent Total",
  "TH": "Total Height",
  "WA": "Wet Areas",
  "WC": "Wet Ceiling Areas",
  "PF1": "Parent Factor1",
  "PF2": "Parent Factor2",
  "PF3": "Parent Factor3",
  "PF4": "Parent Factor4",
  "PF5": "Parent Factor5",
  "ODW": "Opening Door Width",
  "PTM3": "Parent TimberM3",
  "+, -, x, /": "Operation",
}

class AbbreviatedInformation extends React.PureComponent {
  render() {
    return (
      <div>
        <Row>
          <Descriptions bordered title='Symbol and description for field "Abbreviated"'>
            {Object.keys(symbols).map(key => (
              <Descriptions.Item label={key} key={key}>
                {symbols[key]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Row>
      </div>
    )
  }
}

export default AbbreviatedInformation
