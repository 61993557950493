import PageMenu from "App/Component/PageMenu";

// ======= COMPONENT SCREEN
import ExportImportSessions from "./ExportImportSession/";
import ExportImportSessionsNew from "./ExportImportSessionNew/";
import ExportMappingLines from "./ExportMappingLines/";
import ExportMappings from "./ExportMappings/";
import Formulas from "./Formulas/";
import LineTemplates from "./LineTemplates/";
import LineUsages from "./LineUsages/";
import OverrideLines from "./OverrideLines/";
import OverrideSetGroups from "./OverrideSetGroups";
import OverrideSets from "./OverrideSets/";
import PitchFactors from "./PitchFactors/";
import PmHardwareStartingRanges from "./PmHardwareStartingRanges/";
import PmSfSortCodes from "./PmSfSortCodes";
import PmSfSubheadMappings from "./PmSfSubheadMappings";
import PmSortCodes from "./PmSortCodes/";
import PmSubheadMappings from "./PmSubheadMappings/";
import PmTimberStartingRanges from "./PmTimberStartingRanges/";
import PrefillLines from "./PrefillLines/";
import PrefillSetGroups from "./PrefillSetGroups/";
import PrefillSets from "./PrefillSets/";
import ProductGroups from "./ProductGroups/";
import Products from "./Products/";
import RandomMappings from "./RandomMappings/";
import Sections from "./Sections/";
import SubheadMaps from "./SubheadMaps/";
import Subheadings from "./Subheadings/";
import Subsections from "./Subsections/";
import TemplateSets from "./TemplateSets/";
import Units from "./Units/";
import BluebeamMappings from "./BluebeamMappings";

// title sudah tidak dibutuhkan karena menu navigation tidak menggunakan dari routes
const module = "admin";
const app = "estimation";
const routes = [
  {
    name: "Estimation Menu",
    path: `/${module}/${app}`,
    component: PageMenu,
    exact: true,
  },
  {
    routes: [
      {
        name: "Bluebeam Mappings",
        component: BluebeamMappings.List,
        props: { urlName: "api:admin:estimation:bluebeammapping:list" },
      },
      {
        name: "Bluebeam Mappings",
        component: BluebeamMappings.Detail,
        props: { urlName: "api:admin:estimation:bluebeammapping:detail" },
      },
    ]
  },
  {
    // title: "Export Import Sessions",
    routes: [
      {
        name: "Export Import Sessions",
        path: "/admin/estimation/export-import-sessions-old",
        component: ExportImportSessions.List,
        props: { urlName: "api:admin:estimation:exportimportsession:list" },
      },
      {
        name: "Export Import Sessions",
        path: "/admin/estimation/export-import-sessions-old/:id",
        component: ExportImportSessions.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:exportimportsession:detail" },
      }
    ]
  },
  {
    routes: [
      {
        name: "Export Import Sessions",
        component: ExportImportSessionsNew.List,
        props: { urlName: "api:admin:exim:session:list" },
      },
      {
        name: "Export Import Sessions",
        path: "/admin/estimation/export-import-sessions/:id",
        component: ExportImportSessionsNew.Detail,
        exact: false,
        props: { urlName: "api:admin:exim:session:list" },
      }
    ]
  },
  {
    // title: "Export Mapping Lines",
    routes: [
      {
        name: "Export Mapping Lines",
        component: ExportMappingLines.List,
        props: { urlName: "api:admin:estimation:exportmappingline:list" },
      },
      {
        name: "Export Mapping Lines History",
        component: ExportMappingLines.Detail,
        path: "/admin/estimation/export-mapping-lines/:id/history/:historyId",
      },
      {
        name: "Export Mapping Lines",
        component: ExportMappingLines.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:exportmappingline:detail" },
      }
    ]
  },
  {
    // title: "Export Mappings",
    routes: [
      {
        name: "Export Mappings",
        component: ExportMappings.List,
        props: { urlName: "api:admin:estimation:exportmapping:list" },
      },
      {
        name: "Export Mappings History",
        component: ExportMappings.Detail,
        path: "/admin/estimation/export-mappings/:id/history/:historyId",
      },
      {
        name: "Export Mappings",
        component: ExportMappings.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:exportmapping:detail" },
      }
    ]
  },
  {
    // title: "Formulas",
    routes: [
      {
        name: "Formulas",
        component: Formulas.List,
        props: { urlName: "api:admin:estimation:formula:list" },
      },
      {
        name: "Formulas History",
        component: Formulas.HistoryDetail,
        path: "/admin/estimation/formulas/:id/history/:historyId",
      },
      {
        name: "Formulas",
        component: Formulas.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:formula:detail" },
      },
    ]
  },
  {
    // title: "Line Templates",
    routes: [
      {
        name: "Line Templates",
        component: LineTemplates.List,
        props: { urlName: "api:admin:estimation:linetemplate:list" },
      },
      {
        name: "Line Templates History",
        component: LineTemplates.Detail,
        path: "/admin/estimation/line-templates/:id/history/:historyId",
      },
      {
        name: "Line Templates",
        component: LineTemplates.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:linetemplate:detail" },
      }
    ]
  },
  {
    // title: "Line Usages",
    routes: [
      {
        name: "Line Usages",
        component: LineUsages.List,
        props: { urlName: "api:admin:estimation:lineusage:list" },
      },
      {
        name: "Line Usages History",
        component: LineUsages.Detail,
        path: "/admin/estimation/line-usages/:id/history/:historyId",
      },
      {
        name: "Line Usages",
        component: LineUsages.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:lineusage:detail" },
      }
    ]
  },
  {
    // title: "Override Lines",
    routes: [
      {
        name: "Override Lines",
        component: OverrideLines.List,
        props: { urlName: "api:admin:estimation:overrideline:list" },
      },
      {
        name: "Override Lines History",
        component: OverrideLines.Detail,
        path: "/admin/estimation/override-lines/:id/history/:historyId",
      },
      {
        name: "Override Lines",
        component: OverrideLines.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:overrideline:detail" },
      }
    ]
  },
  {
    // title: "Override Set Groups",
    routes: [
      {
        name: "Override Groups",
        component: OverrideSetGroups.List,
        props: { urlName: "api:admin:estimation:overridesetgroup:list" },
      },
      {
        name: "Override Groups History",
        component: OverrideSetGroups.Detail,
        path: "/admin/estimation/override-set-groups/:id/history/:historyId",
      },
      {
        name: "Override Groups",
        component: OverrideSetGroups.OrderManagement,
        path: "/admin/estimation/override-set-groups/order-management",
        props: { urlName: "api:admin:estimation:overridesetgroup:detail" },
      },
      {
        name: "Override Groups",
        component: OverrideSetGroups.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:overridesetgroup:detail" },
      }
    ]
  },
  {
    // title: "Override Sets",
    routes: [
      {
        name: "Override Sets",
        component: OverrideSets.List,
        props: { urlName: "api:admin:estimation:overrideset:list" },
      },
      {
        name: "Override Sets History",
        component: OverrideSets.Detail,
        path: "/admin/estimation/override-sets/:id/history/:historyId",
      },
      {
        name: "Override Sets",
        component: OverrideSets.OrderManagement,
        path: "/admin/estimation/override-sets/order-management",
        props: { urlName: "api:admin:estimation:overrideset:detail" },
      },
      {
        name: "Override Sets",
        component: OverrideSets.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:overrideset:detail" },
      }
    ]
  },
  {
    // title: "Pitch Factors",
    routes: [
      {
        name: "Pitch Factors",
        component: PitchFactors.List,
        props: { urlName: "api:admin:estimation:pitchfactor:list" },
      },
      {
        name: "Pitch Factors History",
        component: PitchFactors.Detail,
        path: "/admin/estimation/pitch-factors/:id/history/:historyId",
      },
      {
        name: "Pitch Factors",
        component: PitchFactors.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pitchfactor:detail" },
      }
    ]
  },
  {
    // title: "PM Hardware Starting Ranges",
    routes: [
      {
        name: "PM Hardware Starting Ranges",
        component: PmHardwareStartingRanges.List,
        props: { urlName: "api:admin:estimation:pmhardwarestartingrange:list" },
      },
      {
        name: "PM Hardware Starting Ranges History",
        component: PmHardwareStartingRanges.Detail,
        path: "/admin/estimation/pm-hardware-starting-ranges/:id/history/:historyId",
      },
      {
        name: "PM Hardware Starting Ranges",
        component: PmHardwareStartingRanges.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmhardwarestartingrange:detail" },
      }
    ]
  },
  {
    routes: [
      {
        name: "PM Salesforce Sort Codes",
        component: PmSfSortCodes.List,
        props: { urlName: "api:admin:estimation:pmsfsortcode:list" },
      },
      {
        name: "PM Salesforce Sort Codes",
        component: PmSfSortCodes.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmsfsortcode:detail" },
      }
    ]
  },
  {
    routes: [
      {
        name: "PM Salesforce Subhead Mappings",
        component: PmSfSubheadMappings.List,
        props: { urlName: "api:admin:estimation:pmsfsubheadmapping:list" },
      },
      {
        name: "PM Salesforce Subhead Mappings",
        component: PmSfSubheadMappings.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmsfsubheadmapping:detail" },
      }
    ]
  },
  {
    // title: "PM Sort Codes",
    routes: [
      {
        name: "PM Sort Codes",
        component: PmSortCodes.List,
        props: { urlName: "api:admin:estimation:pmsortcode:list" },
      },
      {
        name: "PM Sort Codes History",
        component: PmSortCodes.Detail,
        path: "/admin/estimation/pm-sort-codes/:id/history/:historyId",
      },
      {
        name: "PM Sort Codes",
        component: PmSortCodes.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmsortcode:detail" },
      }
    ]
  },
  {
    // title: "PM Subhead Mappings",
    routes: [
      {
        name: "PM Subhead Mappings",
        component: PmSubheadMappings.List,
        props: { urlName: "api:admin:estimation:pmsubheadmapping:list" },
      },
      {
        name: "PM Subhead Mappings History",
        component: PmSubheadMappings.Detail,
        path: "/admin/estimation/pm-subhead-mappings/:id/history/:historyId",
      },
      {
        name: "PM Subhead Mappings",
        component: PmSubheadMappings.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmsubheadmapping:detail" },
      }
    ]
  },
  {
    // title: "PM Timber Starting Ranges",
    routes: [
      {
        name: "PM Timber Starting Ranges",
        component: PmTimberStartingRanges.List,
        props: { urlName: "api:admin:estimation:pmtimberstartingrange:list" },
      },
      {
        name: "PM Timber Starting Ranges History",
        component: PmTimberStartingRanges.Detail,
        path: "/admin/estimation/pm-timber-starting-ranges/:id/history/:historyId",
      },
      {
        name: "PM Timber Starting Ranges",
        component: PmTimberStartingRanges.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:pmtimberstartingrange:detail" },
      }
    ]
  },
  {
    // title: "Prefill Lines",
    routes: [
      {
        name: "Prefill Lines",
        component: PrefillLines.List,
        props: { urlName: "api:admin:estimation:prefillline:list" },
      },
      {
        name: "Prefill Lines History",
        component: PrefillLines.Detail,
        path: "/admin/estimation/prefill-lines/:id/history/:historyId",
      },
      {
        name: "Prefill Lines",
        component: PrefillLines.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:prefillline:detail" },
      }
    ]
  },
  {
    // title: "Prefill Set Groups",
    routes: [
      {
        name: "Prefill Groups",
        component: PrefillSetGroups.List,
        props: { urlName: "api:admin:estimation:prefillsetgroup:list" },
      },
      {
        name: "Prefill Groups History",
        component: PrefillSetGroups.Detail,
        path: "/admin/estimation/prefill-set-groups/:id/history/:historyId",
      },
      {
        name: "Prefill Groups",
        component: PrefillSetGroups.OrderManagement,
        path: "/admin/estimation/prefill-set-groups/order-management",
        props: { urlName: "api:admin:estimation:prefillsetgroup:detail" },
      },
      {
        name: "Prefill Groups",
        component: PrefillSetGroups.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:prefillsetgroup:detail" },
      }
    ]
  },
  {
    // title: "Prefill Sets",
    routes: [
      {
        name: "Prefill Sets",
        component: PrefillSets.List,
        props: { urlName: "api:admin:estimation:prefillset:list" },
      },
      {
        name: "Prefill Sets History",
        component: PrefillSets.Detail,
        path: "/admin/estimation/prefill-sets/:id/history/:historyId",
      },
      {
        name: "Prefill Sets",
        component: PrefillSets.OrderManagement,
        path: "/admin/estimation/prefill-sets/order-management",
        props: { urlName: "api:admin:estimation:prefillset:detail" },
      },
      {
        name: "Prefill Sets",
        component: PrefillSets.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:prefillset:detail" },
      },
    ]
  },
  {
    // title: "Product Groups",
    routes: [
      {
        name: "Product Groups",
        component: ProductGroups.List,
        props: { urlName: "api:admin:estimation:productgroup:list" },
      },
      {
        name: "Product Groups History",
        component: ProductGroups.Detail,
        path: "/admin/estimation/product-groups/:id/history/:historyId",
      },
      {
        name: "Product Groups",
        component: ProductGroups.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:productgroup:detail" },
      }
    ]
  },
  {
    // title: "Products",
    routes: [
      {
        name: "Products",
        component: Products.List,
        props: { urlName: "api:admin:estimation:product:list" },
      },
      {
        name: "Products History",
        component: Products.Detail,
        path: "/admin/estimation/products/:id/history/:historyId",
      },
      {
        name: "Products",
        component: Products.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:product:detail" },
      }
    ]
  },
  {
    // title: "Random Mappings",
    routes: [
      {
        name: "Random Mappings",
        component: RandomMappings.List,
        props: { urlName: "api:admin:estimation:randommapping:list" },
      },
      {
        name: "Random Mappings History",
        component: RandomMappings.Detail,
        path: "/admin/estimation/random-mappings/:id/history/:historyId",
      },
      {
        name: "Random Mappings",
        component: RandomMappings.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:randommapping:detail" },
      }
    ]
  },
  {
    // title: "Sections",
    routes: [
      {
        name: "Sections",
        component: Sections.List,
        props: { urlName: "api:admin:estimation:section:list" },
      },
      {
        name: "Sections History",
        component: Sections.Detail,
        path: "/admin/estimation/sections/:id/history/:historyId",
      },
      {
        name: "Sections",
        component: Sections.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:section:detail" },
      }
    ]
  },
  {
    // title: "Subhead Maps",
    routes: [
      {
        name: "Subhead Maps",
        component: SubheadMaps.List,
        props: { urlName: "api:admin:estimation:subheadmap:list" },
      },
      {
        name: "Subhead Maps",
        component: SubheadMaps.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:subheadmap:detail" },
      }
    ]
  },
  {
    // title: "Subheadings",
    routes: [
      {
        name: "Subheadings",
        component: Subheadings.List,
        props: { urlName: "api:admin:estimation:subheading:list" },
      },
      {
        name: "Subheadings History",
        component: Subheadings.Detail,
        path: "/admin/estimation/subheadings/:id/history/:historyId",
      },
      {
        name: "Subheadings",
        component: Subheadings.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:subheading:detail" },
      }
    ]
  },
  {
    // title: "Subsections",
    routes: [
      {
        name: "Subsections",
        component: Subsections.List,
        props: { urlName: "api:admin:estimation:subsection:list" },
      },
      {
        name: "Subsections History",
        component: Subsections.Detail,
        path: "/admin/estimation/subsections/:id/history/:historyId",
      },
      {
        name: "Subsections",
        component: Subsections.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:subsection:detail" },
      }
    ]
  },
  {
    // title: "Template Sets",
    routes: [
      {
        name: "Template Sets",
        component: TemplateSets.List,
        props: { urlName: "api:admin:estimation:templateset:list" },
      },
      {
        name: "Template Sets History",
        component: TemplateSets.Detail,
        path: "/admin/estimation/template-sets/:id/history/:historyId",
      },
      {
        name: "Template Sets",
        component: TemplateSets.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:templateset:detail" },
      }
    ]
  },
  {
    // title: "Units",
    routes: [
      {
        name: "Units",
        component: Units.List,
        props: { urlName: "api:admin:estimation:unit:list" },
      },
      {
        name: "Units History",
        component: Units.Detail,
        path: "/admin/estimation/units/:id/history/:historyId",
      },
      {
        name: "Units",
        component: Units.Detail,
        exact: false,
        props: { urlName: "api:admin:estimation:unit:detail" },
      }
    ]
  }
];

export default { appName: "Estimations", routes };
