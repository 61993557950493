// estimation
import bluebeamMappingOptions from 'App/Screens/Estimation/BluebeamMappings/options'
import exportImportSessionOptions from 'App/Screens/Estimation/ExportImportSessionNew/options'
import exportMappingLinesOptions from 'App/Screens/Estimation/ExportMappingLines/options'
import exportMappingOptions from 'App/Screens/Estimation/ExportMappings/options'
import formulaOptions from 'App/Screens/Estimation/Formulas/options'
import lineTemplateOptions from 'App/Screens/Estimation/LineTemplates/options'
import lineUsageOptions from 'App/Screens/Estimation/LineUsages/options'
import overrideLineOptions from 'App/Screens/Estimation/OverrideLines/options'
import overrideSetGroupOptions from 'App/Screens/Estimation/OverrideSetGroups/options'
import overrideSetOptions from 'App/Screens/Estimation/OverrideSets/options'
import pitchFactorOptions from 'App/Screens/Estimation/PitchFactors/options'
import pmHardwareStartingRangeOptions from 'App/Screens/Estimation/PmHardwareStartingRanges/options'
import pmSfSortCodeOptions from 'App/Screens/Estimation/PmSfSortCodes/options'
import pmSfSubheadMappingOptions from 'App/Screens/Estimation/PmSfSubheadMappings/options'
import pmSortCodeOptions from 'App/Screens/Estimation/PmSortCodes/options'
import pmSubheadMappingOptions from 'App/Screens/Estimation/PmSubheadMappings/options'
import pmTimberStartingRangeOptions from 'App/Screens/Estimation/PmTimberStartingRanges/options'
import prefillLineOptions from 'App/Screens/Estimation/PrefillLines/options'
import prefillSetGroupOptions from 'App/Screens/Estimation/PrefillSetGroups/options'
import prefillSetOptions from 'App/Screens/Estimation/PrefillSets/options'
import productGroupOptions from 'App/Screens/Estimation/ProductGroups/options'
import productOptions from 'App/Screens/Estimation/Products/options'
import randomMappingOptions from 'App/Screens/Estimation/RandomMappings/options'
import sectionOptions from 'App/Screens/Estimation/Sections/options'
import subheadingOptions from 'App/Screens/Estimation/Subheadings/options'
import subheadMapOptions from 'App/Screens/Estimation/SubheadMaps/options'
import subsectionOptions from 'App/Screens/Estimation/Subsections/options'
import templateSetOptions from 'App/Screens/Estimation/TemplateSets/options'
import unitOptions from 'App/Screens/Estimation/Units/options'
// geo
import countryOptions from 'App/Screens/Geo/Countries/options'
import provinceOptions from 'App/Screens/Geo/Provinces/options'
import cityOptions from 'App/Screens/Geo/Cities/options'
// job
import accountOptions from 'App/Screens/Job/Accounts/options'
import buildingOptions from 'App/Screens/Job/Buildings/options'
import buildingTypeOptions from 'App/Screens/Job/BuildingTypes/options'
import extraChargeJobOptions from 'App/Screens/Job/ExtraChargeJobs/options'
import extraChargeOptions from 'App/Screens/Job/ExtraCharges/options'
import extraChargeSizeOptions from 'App/Screens/Job/ExtraChargeSizes/options'
import holidayOptions from 'App/Screens/Job/Holidays/options'
import jobGradeOptions from 'App/Screens/Job/JobGrades/options'
import jobQueueOptions from 'App/Screens/Job/JobQueues/options'
import jobStatusHistoryOptions from 'App/Screens/Job/JobStatusHistories/options'
import jobStatusNotSyncedOptions from 'App/Screens/Job/JobStatusNotSynceds/options'
import jobTypeOptions from 'App/Screens/Job/JobTypes/options'
import merchantGroupOptions from 'App/Screens/Job/MerchantGroups/options'
import merchantOptions from 'App/Screens/Job/Merchants/options'
import operatingBranchOptions from 'App/Screens/Job/OperatingBranches/options'
import rangeOptions from 'App/Screens/Job/Ranges/options'
import rateNameOptions from 'App/Screens/Job/RateNames/options'
import rateTypeOptions from 'App/Screens/Job/RateTypes/options'
import tagMerchantOptions from 'App/Screens/Job/TagMerchants/options'
import tagOptions from 'App/Screens/Job/Tags/options'
import takeOffTypeOptions from 'App/Screens/Job/TakeOffTypes/options'
// erf
import erfMappingOptions from 'App/Screens/ERF/ErfMappings/options'
// multisite
import branchOptions from 'App/Screens/Multisite/Branches/options'
import pendingMonitorReportOptions from 'App/Screens/Multisite/PendingMonitorReports/options'
import serverOptions from 'App/Screens/Multisite/Servers/options'
import rateOptions from 'App/Screens/Multisite/BranchRatings/options'
// notification
import notificationOptions from 'App/Screens/Notification/Notifications/options'
import broadcastOptions from 'App/Screens/Notification/Broadcast/options'
// report
import mappingModeOptions from 'App/Screens/Report/MappingModes/options'
import mmSecurityOptions from 'App/Screens/Report/MmSecurities/options'
import skuMappingOptions from 'App/Screens/Report/SkuMappings/options'
import specialMessageOptions from 'App/Screens/Report/SpecialMessages/options'
// user
import staffLeaveOptions from 'App/Screens/Users/StaffLeaves/options'
import staffPositionOptions from 'App/Screens/Users/StaffPositions/options'

// Rules
// value must object with keys "bulkOptions" (required) or another
// bulkOptions must be object in array
// example:
// key: {
//   bulkOptions: [
//     { name, field, value, label, permissionCode }
//   ]
// }

// camelCase key
export default {
  // estimation
  bluebeamMappings: bluebeamMappingOptions,
  exportImportSessions: exportImportSessionOptions,
  exportMappingLines: exportMappingLinesOptions,
  exportMappings: exportMappingOptions,
  formulas: formulaOptions,
  lineTemplates: lineTemplateOptions,
  lineUsages: lineUsageOptions,
  overrideLines: overrideLineOptions,
  overrideSetGroups: overrideSetGroupOptions,
  overrideSets: overrideSetOptions,
  pitchFactors: pitchFactorOptions,
  pmHardwareStartingRanges: pmHardwareStartingRangeOptions,
  pmSfSortCodes: pmSfSortCodeOptions,
  pmSfSubheadMappings: pmSfSubheadMappingOptions,
  pmSortCodes: pmSortCodeOptions,
  pmSubheadMappings: pmSubheadMappingOptions,
  pmTimberStartingRanges: pmTimberStartingRangeOptions,
  prefillLines: prefillLineOptions,
  prefillSetGroups: prefillSetGroupOptions,
  prefillSets: prefillSetOptions,
  productGroups: productGroupOptions,
  products: productOptions,
  randomMappings: randomMappingOptions,
  sections: sectionOptions,
  subheadings: subheadingOptions,
  subheadMaps: subheadMapOptions,
  subsections: subsectionOptions,
  templateSets: templateSetOptions,
  units: unitOptions,
  // geo
  countries: countryOptions,
  provinces: provinceOptions,
  cities: cityOptions,
  // job
  accounts: accountOptions,
  buildings: buildingOptions,
  buildingTypes: buildingTypeOptions,
  extraChargeJobs: extraChargeJobOptions,
  extraCharges: extraChargeOptions,
  extraChargeSizes: extraChargeSizeOptions,
  holidays: holidayOptions,
  jobGrades: jobGradeOptions,
  jobQueues: jobQueueOptions,
  jobStatusHistories: jobStatusHistoryOptions,
  jobStatusNotSynceds: jobStatusNotSyncedOptions,
  jobTypes: jobTypeOptions,
  merchantGroups: merchantGroupOptions,
  merchants: merchantOptions,
  operatingBranches: operatingBranchOptions,
  ranges: rangeOptions,
  rateNames: rateNameOptions,
  rateTypes: rateTypeOptions,
  tags: tagOptions,
  tagMerchants: tagMerchantOptions,
  takeOffTypes: takeOffTypeOptions,
  // erf
  erfMappings: erfMappingOptions,
  // multisite
  branches: branchOptions,
  pendingMonnitorReports: pendingMonitorReportOptions,
  servers: serverOptions,
  branchRatings: rateOptions,
  // notification
  notifications: notificationOptions,
  broadcasts: broadcastOptions,
  // report
  mappingModes: mappingModeOptions,
  mmSecurities: mmSecurityOptions,
  skuMappings: skuMappingOptions,
  specialMessages: specialMessageOptions,
  // user
  staffLeaves: staffLeaveOptions,
  staffPositions: staffPositionOptions,
}