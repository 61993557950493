export default [
  {
    title: 'Pm code',
    required: true,
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'pm_code',
    order: 0,
  }, {
    title: 'Pm description',
    required: true,
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'pm_description',
    order: 1,
  }, {
    title: 'Qs subheading',
    type: 'select_multiple',
    data: 'subheadings',
    initialValue: '',
    note_text: '',
    additional: 'add',
    selectedKey: 'pk',
    selectedValue: 'label',
    dataIndex: 'qs_subheadings',
    order: 2,
  }, {
    title: 'Sequence',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'sequence',
    order: 3,
  }, {
    title: 'Default sort code',
    type: 'text',
    data: '',
    initialValue: '',
    note_text: '',
    additional: '',
    dataIndex: 'default_sort_code',
    order: 4,
  },
]