import { BaseList, BaseDetail } from "App/Component/Bases";
import fields from "./fields";
import descriptor from "./descriptor";
import options from "./options";

const title = "PM Salesforce Subhead Mappings";
const urlData = "pmSfSubheadMappings";
const bulkName = "pm_code";

class List extends BaseList {
  constructor(props) {
    super(props)

    this.title = title;
    this.urlData = urlData;
    this.bulkName = bulkName;
    this.propsList = {
      descriptor,
      exportImport: options.exportImportOptions,
    }
  }

  getModelName = () => 'pmsfsubheadmapping'
}

class Detail extends BaseDetail {
  constructor(props) {
    super(props)

    this.title = title
    this.urlData = urlData
    this.bulkName = bulkName
    this.fields = fields
    this.propsDetail = {
      urlKeyHistory: 'api:admin:estimation:pmsubheadmapping:detail-revision',
      keyLabel: 'pm_description'
    }
  }
}

export default { List, Detail };